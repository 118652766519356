<ul>
    <li *ngFor="let video of videos.value">
        <div class="video" (click)="open(video.url)">
            <div class="thumbnail">
                <img src="{{ video.thumbnail }}" alt="">
            </div>
            <div class="title">{{ video.title }}</div>
        </div>
    </li>
</ul>
