<ul>
    <li *ngFor="let post of posts.value">
        <div class="photo" (click)="open(post.url)">
            <div class="thumbnail">
                <img src="{{ post.thumbnail }}" alt="">
            </div>
            <div class="title">{{ post.title }}</div>
        </div>
    </li>
</ul>
