import {Injectable} from '@angular/core';

import {io} from "socket.io-client";

import {ChannelStream} from "../interface/channel-stream";
import {Planning} from "../interface/planning";
import {Video} from "../interface/video";
import {Post} from "../interface/post";
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class SocketService {

    socket;

    dataPlanning: Planning | any = new BehaviorSubject<Planning | any>({
        week: [],
        holiday: [],
        extra: {holiday: false, next: null}
    });

    dataChannelStream: ChannelStream | any = new BehaviorSubject<ChannelStream | any>({
        stream: null,
        host: null
    });

    dataYoutube: Video[] | any = new BehaviorSubject<Video[] | any>([]);
    dataYoutubeVod: Video[] | any = new BehaviorSubject<Video[] | any>([]);
    dataYoutubeShorts: Video[] | any = new BehaviorSubject<Video[] | any>([]);
    dataInstagram: Post[] | any = new BehaviorSubject<Post[] | any>([]);

    constructor() {
        this.initializeSocket();
    }

    initializeSocket() {

        this.socket = io("https://websocket.locklear.fr", {transports: ['websocket']});

        this.socket.on('connect', async () => {
            console.log("Connexion au socket réussie.");
            this.socket.emit("join-room", "locklear-mobile");
        });

        this.socket.on('update-twitch-stream-client', async (data) => {
            this.dataChannelStream.next(data);
        });

        this.socket.on('update-planning-client', async (data) => {
            this.dataPlanning.next(data);
        });

        this.socket.on('update-instagram-client', async (data) => {

            let newDataInstagram = [];

            data.forEach((element) => {
                // @ts-ignore
              newDataInstagram.push({
                    url: element.url,
                    title: element.title,
                    thumbnail: element.thumbnails.standard_resolution.url
                })
            });

            this.dataInstagram.next(newDataInstagram);
        });

        this.socket.on('update-youtube-client', async (data) => {

            let newDataVideos = [];

            data.forEach((element) => {
                // @ts-ignore
              newDataVideos.push({
                    url: element.url,
                    title: element.title,
                    thumbnail: (element.thumbnails.maxres) ? element.thumbnails.maxres.url : element.thumbnails.high.url
                })
            });

            this.dataYoutube.next(newDataVideos);
        });

        this.socket.on('update-youtube-vod-client', async (data) => {

            let newDataVideos = [];

            data.forEach((element) => {
                // @ts-ignore
              newDataVideos.push({
                    url: element.url,
                    title: element.title,
                    thumbnail: (element.thumbnails.maxres) ? element.thumbnails.maxres.url : element.thumbnails.high.url
                })
            });

            this.dataYoutubeVod.next(newDataVideos);
        });

        this.socket.on('update-youtube-shorts-client', async (data) => {

            let newDataVideos = [];

            data.forEach((element) => {
                // @ts-ignore
              newDataVideos.push({
                    url: element.url,
                    title: element.title,
                    thumbnail: (element.thumbnails.maxres) ? element.thumbnails.maxres.url : element.thumbnails.high.url
                })
            });

            this.dataYoutubeShorts.next(newDataVideos);
        });
    }
}
