import {Component} from '@angular/core';

import {SidenavLeftService} from "../../services/sidenav-left.service";
import {SidenavRightService} from "../../services/sidenav-right.service";
import {NotificationService} from "../../services/notification.service";

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {

    permissionWaiting = false;

    constructor(
        public sidenavLeft: SidenavLeftService,
        public sidenavRight: SidenavRightService,
        private notificationService: NotificationService,
    ) {
    }

    sidenavRightToggle() {

        if (this.permissionWaiting === true) return;
        this.permissionWaiting = true;

        Notification.requestPermission().then((result) => {

            if (result === "granted") {

                this.notificationService.initialize();
                this.sidenavRight.toggle();

            } else {

                this.notificationService.currentToken = null;
                alert("Vous devez autoriser les notifications afin d'accéder à cette fonctionnalité.");
            }

        }).catch(() => {

            alert("Vous devez autoriser les notifications afin d'accéder à cette fonctionnalité.");

        }).finally(() => {

            this.permissionWaiting = false;
        });
    }
}
