<mat-drawer-container class="main-container" hasBackdrop="true">

  <mat-drawer #sideNavLeft mode="over" opened="false" position="start">
    <app-sidenav-left></app-sidenav-left>
  </mat-drawer>

  <mat-drawer #sideNavRight class="sidenav-right" mode="over" opened="false" position="end">
    <app-sidenav-right></app-sidenav-right>
  </mat-drawer>

  <mat-drawer-content>

    <app-toolbar></app-toolbar>
    <app-menu></app-menu>

    <router-outlet></router-outlet>

  </mat-drawer-content>
</mat-drawer-container>
