import {Injectable} from '@angular/core';
import * as Flickity from "flickity";

@Injectable({
  providedIn: 'root'
})
export class PlanningCarouselService {

  carousel: Flickity | null = null;

  public setCarousel(carousel: Flickity) {
    this.carousel = carousel;
  }

  public resize() {

    if (this.carousel !== null) {
      this.carousel.resize();
    }
  }
}
