export const environment = {
    production: true,
    version: "1.0.0",
    links: {
        social: {
            twitterLocklear: "https://twitter.com/lockl34r",
            twitterTv: "https://twitter.com/lockl34rtv",
            instagram: "https://www.instagram.com/esl_locklear",
            discord: "https://discord.gg/locklear",
            twitch: "https://www.twitch.tv/locklear",
            youtube: "https://youtube.com/L0ckl34r"
        },
        api: "https://api.locklear.fr",
        site: "https://locklear.fr",
        shop: "https://shop.locklear.fr",
        confidentiality: "https://locklear.fr/privacy",
        stores: {
            android: "https://play.google.com/store/apps/details?id=com.locklear.mobile",
            ios: ""
        }
    },
    firebase: {
      apiKey: "AIzaSyCBlrlqIv8nVguj1Rhd3IPZdqPOg1zaT-0",
      authDomain: "locklear-26250.firebaseapp.com",
      databaseURL: "https://locklear-26250.firebaseio.com",
      projectId: "locklear-26250",
      storageBucket: "locklear-26250.appspot.com",
      messagingSenderId: "549575931255",
      appId: "1:549575931255:web:f45e748442c5de03ca9d72",
      measurementId: "G-CN0060F84L",
      vapidKey: "BJDQamg3RpGbEv7Gx0PeGMpKZ9wle-kHfzIddbzE2W252B3p7FH_SeZ_3ljL4g1MDEtq-3YWemmzXUfVIiVxbuM"
    },
};
