<div *ngIf="socketService.dataChannelStream.value.stream === null" id="countdown-container">
    <div class="sentence">LE PROCHAIN LIVE DÉMARRE DANS</div>
    <div class="timer">{{ streamTimer.value }}</div>
    <div class="legend">
        <span class="legend-day">Jours</span>
        <span class="legend-hour">Heure</span>
        <span class="legend-min">Min</span>
        <span class="legend-sec">Sec</span>
    </div>
</div>

<div *ngIf="socketService.dataChannelStream.value.stream !== null" (click)="openLive()" id="preview-container">
    <div class="thumbnail">
        <img class="preview-image" [src]="previewThumbnail.value" alt=""/>
        <span class="viewers">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="18px" height="18px"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/></svg>
            &nbsp;{{ previewViewers | async }}
        </span>
    </div>
    <div class="title">{{ previewTitle | async }}</div>
</div>

<div [style.display]="socketService.dataPlanning.value.extra.holiday === false" id="planning-container">

    <div class="sentence">PROGRAMME DE LA SEMAINE</div>

    <div class="planning">

        <button type="button" class="carousel-previous" (click)="carouselPrevious()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="18px" height="18px">
                <path d="M0 0h24v24H0V0z" fill="none"/>
                <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"/>
            </svg>
        </button>

        <button type="button" class="carousel-next" (click)="carouselNext()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="18px" height="18px">
                <path d="M0 0h24v24H0V0z" fill="none"/>
                <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"/>
            </svg>
        </button>

        <div class="carousel" #carouselElement></div>
    </div>
</div>

<div *ngIf="socketService.dataPlanning.value.extra.holiday === true" id="holiday-container">
    <div class="sentence">PÉRIODE DE VACANCES</div>
    <div class="content">
        <span style='color: white; font-weight: bold;'>Prochain stream</span><br/>{{ holidayEnd | async }}
    </div>
</div>
