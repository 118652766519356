import {Component} from '@angular/core';

@Component({
    selector: 'app-tab-videos',
    templateUrl: './tab-videos.component.html',
    styleUrls: ['./tab-videos.component.scss']
})
export class TabVideosComponent {

    constructor() {
    }
}
