<div class="main-title">Notifications</div>

<div class="option-container">

    <div>
        <label>
            <input [disabled]="notificationService.currentToken === null" [(ngModel)]="checkedLiveStartNotification" (change)="changeLiveStartNotification(checkedLiveStartNotification)" type="checkbox" class="filled-in"/>
            <span style="color: white; padding-left: 28px;"><span style="position: relative; top: -2px;">En début de live</span></span>
        </label>
    </div>

    <div>
        <label>
            <input [disabled]="notificationService.currentToken === null" [(ngModel)]="checkedPlanningTodayCanceled" (change)="changePlanningTodayCanceledNotification(checkedPlanningTodayCanceled)" type="checkbox" class="filled-in"/>
            <span style="color: white; padding-left: 28px;"><span style="position: relative; top: -2px;">En cas d'annulation de live</span></span>
        </label>
    </div>

    <div style="margin-top: 15px;">
        <label>
            <input [disabled]="notificationService.currentToken === null" [(ngModel)]="checkedYoutubeVideoNotification" (change)="changeYoutubeNotification(checkedYoutubeVideoNotification)" type="checkbox" class="filled-in"/>
            <span style="color: white; padding-left: 28px;"><span style="position: relative; top: -2px;">À la sortie d'une vidéo</span></span>
        </label>
    </div>

    <div>
        <label>
            <input [disabled]="notificationService.currentToken === null" [(ngModel)]="checkedYoutubeVideoVodNotification" (change)="changeYoutubeVodNotification(checkedYoutubeVideoVodNotification)" type="checkbox" class="filled-in"/>
            <span style="color: white; padding-left: 28px;"><span style="position: relative; top: -2px;">À la sortie d'une VOD</span></span>
        </label>
    </div>

    <div>
        <label>
            <input [disabled]="notificationService.currentToken === null" [(ngModel)]="checkedYoutubeVideoShortsNotification" (change)="changeYoutubeShortsNotification(checkedYoutubeVideoShortsNotification)" type="checkbox" class="filled-in"/>
            <span style="color: white; padding-left: 28px;"><span style="position: relative; top: -2px;">À la sortie d'une vidéo shorts</span></span>
        </label>
    </div>

    <div>
        <label>
            <input [disabled]="notificationService.currentToken === null" [(ngModel)]="checkedInstagramPhotoNotification" (change)="changeInstagramPhotoNotification(checkedInstagramPhotoNotification)" type="checkbox" class="filled-in"/>
            <span style="color: white; padding-left: 28px;"><span style="position: relative; top: -2px;">À la publication d'une photo</span></span>
        </label>
    </div>

    <hr/>

    <div>
        <label>
            <input [disabled]="notificationService.currentToken === null" [(ngModel)]="checkedNotificationBeta" (change)="changeBetaNotification(checkedNotificationBeta)" type="checkbox" class="filled-in"/>
            <span style="color: white; padding-left: 28px;"><span style="position: relative; top: -2px;">Notification bêta</span></span>
        </label>
    </div>
</div>

<ul>

</ul>
