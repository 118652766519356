import {Component} from '@angular/core';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-sidenav-left',
    templateUrl: './sidenav-left.component.html',
    styleUrls: ['./sidenav-left.component.scss']
})
export class SidenavLeftComponent {

    public appVersion;

    constructor() {
        this.appVersion = environment.version;
    }

    async clickVersion() {

    }

    async clickSite() {
        await window.open(environment.links.site)
    }

    async clickShop() {
        await window.open(environment.links.shop)
    }

    async clickYoutube() {
        await window.open(environment.links.social.youtube)
    }

    async clickTwitch() {
        await window.open(environment.links.social.twitch)
    }

    async clickTwitter() {
        await window.open(environment.links.social.twitterTv)
    }

    async clickInstagram() {
        await window.open(environment.links.social.instagram)
    }

    async clickDiscord() {
        await window.open(environment.links.social.discord)
    }

    async clickConfidentiality() {
        await window.open(environment.links.confidentiality)
    }
}
