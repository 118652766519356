import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialogModule} from '@angular/material/dialog';
import { CommonModule } from "@angular/common";

import { environment } from "../environments/environment";
import { initializeApp } from "firebase/app";
initializeApp(environment.firebase);

import {ToolbarComponent} from './components/toolbar/toolbar.component';
import {MatSidenavModule} from "@angular/material/sidenav";
import {SidenavLeftComponent} from './components/sidenav-left/sidenav-left.component';
import {SidenavRightComponent} from './components/sidenav-right/sidenav-right.component';
import {MenuComponent} from './components/menu/menu.component';
import {TabVideosComponent} from './components/tab-videos/tab-videos.component';
import {TabInstagramComponent} from './components/tab-instagram/tab-instagram.component';
import {TabStreamComponent} from './components/tab-stream/tab-stream.component';
import {TabSoundboardComponent} from './components/tab-soundboard/tab-soundboard.component';
import {FormsModule} from "@angular/forms";
import {TabVideosYoutubeComponent} from './components/tab-videos-youtube/tab-videos-youtube.component';
import {TabVideosYoutubeVodComponent} from './components/tab-videos-youtube-vod/tab-videos-youtube-vod.component';
import {TabVideosYoutubeShortsComponent} from "./components/tab-videos-youtube-shorts/tab-videos-youtube-shorts.component";

@NgModule({
  declarations: [
    AppComponent,
    ToolbarComponent,
    SidenavLeftComponent,
    SidenavRightComponent,
    MenuComponent,
    TabVideosComponent,
    TabVideosYoutubeComponent,
    TabVideosYoutubeVodComponent,
    TabVideosYoutubeShortsComponent,
    TabInstagramComponent,
    TabStreamComponent,
    TabSoundboardComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatDialogModule,
    AppRoutingModule,
    FormsModule,
    CommonModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
