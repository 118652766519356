import { NgModule } from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

import {TabSoundboardComponent} from "./components/tab-soundboard/tab-soundboard.component";
import {TabInstagramComponent} from "./components/tab-instagram/tab-instagram.component";
import {TabVideosComponent} from "./components/tab-videos/tab-videos.component";
import {TabStreamComponent} from "./components/tab-stream/tab-stream.component";
import {TabVideosYoutubeComponent} from "./components/tab-videos-youtube/tab-videos-youtube.component";
import {TabVideosYoutubeVodComponent} from "./components/tab-videos-youtube-vod/tab-videos-youtube-vod.component";
import {TabVideosYoutubeShortsComponent} from "./components/tab-videos-youtube-shorts/tab-videos-youtube-shorts.component";

const routes: Routes = [
  { path: '', component: TabStreamComponent },
  {
    path: 'videos',
    component: TabVideosComponent,
    children: [
      {path: '', redirectTo: '/videos/youtube', pathMatch: 'full'},
      {path: 'youtube', component: TabVideosYoutubeComponent},
      {path: 'replays', component: TabVideosYoutubeVodComponent},
      {path: 'shorts', component: TabVideosYoutubeShortsComponent}
    ]
  },
  { path: 'instagram', component: TabInstagramComponent },
  { path: 'playlists', component: TabSoundboardComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
