import {Component, OnInit} from '@angular/core';
import {SocketService} from "../../services/socket.service";
import {Post} from "../../interface/post";
import {BehaviorSubject} from "rxjs";

@Component({
    selector: 'app-tab-instagram',
    templateUrl: './tab-instagram.component.html',
    styleUrls: ['./tab-instagram.component.scss']
})
export class TabInstagramComponent implements OnInit {

    posts = new BehaviorSubject<Post[]>([]);

    constructor(public socketService: SocketService) {
    }

    ngOnInit(): void {
        this.observePosts();
    }

    async open(url) {
        await window.open(url);
    }

    observePosts() {

        this.socketService.dataInstagram.subscribe((newDataInstagram: Post[]) => {

            let newPosts: Post[] | any = [];

            newDataInstagram.forEach((post: Post) => {

                let currentPost: Post | any = {url: post.url, title: post.title, thumbnail: post.thumbnail};
                if (post.hasOwnProperty("thumbnail64")) currentPost.thumbnail = post.thumbnail64;

                newPosts.push(currentPost);
            });

            this.posts.next(newPosts);
        });
    }
}
