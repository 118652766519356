import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {Notification} from "../../interface/notification";
import {NotificationService} from "../../services/notification.service";

@Component({
    selector: 'app-sidenav-right',
    templateUrl: './sidenav-right.component.html',
    styleUrls: ['./sidenav-right.component.scss']
})
export class SidenavRightComponent implements OnInit {

    checkedLiveStartNotification = false;
    checkedYoutubeVideoNotification = false;
    checkedYoutubeVideoVodNotification = false;
    checkedYoutubeVideoShortsNotification = false;
    checkedInstagramPhotoNotification = false;
    checkedPlanningTodayCanceled = false;
    checkedNotificationBeta = false;

    constructor(public notificationService: NotificationService) {

    }

    async ngOnInit() {

        this.notificationService.initialize();

        this.checkedLiveStartNotification = (JSON.parse(localStorage.getItem('optionNotificationLiveStart')));
        this.checkedYoutubeVideoNotification = (JSON.parse(localStorage.getItem('optionNotificationYoutubeVideo')));
        this.checkedYoutubeVideoVodNotification = (JSON.parse(localStorage.getItem('optionNotificationYoutubeVideoVod')));
        this.checkedYoutubeVideoShortsNotification = (JSON.parse(localStorage.getItem('optionNotificationYoutubeVideoShorts')));
        this.checkedInstagramPhotoNotification = (JSON.parse(localStorage.getItem('optionNotificationInstagramPhoto')));
        this.checkedPlanningTodayCanceled = (JSON.parse(localStorage.getItem('optionNotificationPlanningTodayCanceled')));
        this.checkedNotificationBeta = (JSON.parse(localStorage.getItem('optionNotificationBeta')));
    }

    async changeLiveStartNotification(checked: boolean) {

        (checked) ? this.notificationService.subscribeTopic("web-notification-live-start") : this.notificationService.unsubscribeTopic("web-notification-live-start");
        localStorage.setItem('optionNotificationLiveStart', JSON.stringify(checked));
    }

    async changeYoutubeNotification(checked: boolean) {

        (checked) ? this.notificationService.subscribeTopic("web-notification-youtube-video") : this.notificationService.unsubscribeTopic("web-notification-youtube-video");
        localStorage.setItem('optionNotificationYoutubeVideo', JSON.stringify(checked));
    }

    async changeYoutubeVodNotification(checked: boolean) {

        (checked) ? this.notificationService.subscribeTopic("web-notification-youtube-video-vod") : this.notificationService.unsubscribeTopic("web-notification-youtube-video-vod");
        localStorage.setItem('optionNotificationYoutubeVideoVod', JSON.stringify(checked));
    }

    async changeYoutubeShortsNotification(checked: boolean) {

        (checked) ? this.notificationService.subscribeTopic("web-notification-youtube-video-shorts") : this.notificationService.unsubscribeTopic("web-notification-youtube-video-shorts");
        localStorage.setItem('optionNotificationYoutubeVideoShorts', JSON.stringify(checked));
    }

    async changeInstagramPhotoNotification(checked: boolean) {

        (checked) ? this.notificationService.subscribeTopic("web-notification-instagram-photo") : this.notificationService.unsubscribeTopic("web-notification-instagram-photo");
        localStorage.setItem('optionNotificationInstagramPhoto', JSON.stringify(checked));
    }

    async changePlanningTodayCanceledNotification(checked: boolean) {

        (checked) ? this.notificationService.subscribeTopic("web-notification-planning-today-canceled") : this.notificationService.unsubscribeTopic("web-notification-planning-today-canceled");
        localStorage.setItem('optionNotificationPlanningTodayCanceled', JSON.stringify(checked));
    }

    async changeBetaNotification(checked: boolean) {

        (checked) ? this.notificationService.subscribeTopic("web-notification-beta") : this.notificationService.unsubscribeTopic("web-notification-beta");
        localStorage.setItem('optionNotificationBeta', JSON.stringify(checked));
    }
}
