import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from "rxjs";

import {SocketService} from "../../services/socket.service";
import {Video} from "../../interface/video";

@Component({
  selector: 'app-tab-videos-youtube-shorts',
  templateUrl: './tab-videos-youtube-shorts.component.html',
  styleUrls: ['./tab-videos-youtube-shorts.component.scss']
})
export class TabVideosYoutubeShortsComponent implements OnInit {

  videos = new BehaviorSubject<Video[]>([]);

  constructor(public socketService: SocketService) {
  }

  ngOnInit(): void {
    this.observeVideos();
  }

  async open(url) {
    await window.open(url);
  }

  observeVideos() {

    this.socketService.dataYoutubeShorts.subscribe((newDataYoutube: Video[]) => {

      let newVideos: Video[] | any = [];

      newDataYoutube.forEach((video: Video) => {

        let currentVideo: Video | any = {url: video.url, title: video.title, thumbnail: video.thumbnail};
        if (video.hasOwnProperty("thumbnail64")) currentVideo.thumbnail = video.thumbnail64;

        newVideos.push(video);
      });

      newVideos = newVideos.slice(0, 10);

      this.videos.next(newVideos);
    });
  }
}
