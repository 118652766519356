import {Injectable} from '@angular/core';
import {getMessaging, getToken} from "firebase/messaging";
import {environment} from "../../environments/environment";
import {onMessage} from "@firebase/messaging";
import firebase from "firebase/compat";
import MessagePayload = firebase.messaging.MessagePayload;

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    public currentToken: string | null = null;

    constructor() {

        if ('permissions' in navigator) {
            navigator.permissions.query({name: 'notifications'}).then((notificationPerm) => {

                notificationPerm.onchange = () => {
                    if (notificationPerm.state === "denied" || notificationPerm.state === "prompt") this.currentToken = null;
                };
            });
        }
    }

    initialize() {

        if (localStorage.getItem('optionNotificationLiveStart') === null) localStorage.setItem('optionNotificationLiveStart', "true");
        if (localStorage.getItem('optionNotificationYoutubeVideo') === null) localStorage.setItem('optionNotificationYoutubeVideo', "true");
        if (localStorage.getItem('optionNotificationYoutubeVideoVod') === null) localStorage.setItem('optionNotificationYoutubeVideoVod', "false");
        if (localStorage.getItem('optionNotificationYoutubeVideoShorts') === null) localStorage.setItem('optionNotificationYoutubeVideoShorts', "false");
        if (localStorage.getItem('optionNotificationInstagramPhoto') === null) localStorage.setItem('optionNotificationInstagramPhoto', "true");
        if (localStorage.getItem('optionNotificationPlanningTodayCanceled') === null) localStorage.setItem('optionNotificationPlanningTodayCanceled', "true");
        if (localStorage.getItem('optionNotificationBeta') === null) localStorage.setItem('optionNotificationBeta', "false");

        const messaging = getMessaging();
        getToken(messaging).then((token) => {

            this.currentToken = token;

            let lastToken = localStorage.getItem("lastToken");
            if (lastToken === null || lastToken !== token) {

                localStorage.setItem('lastToken', token);

                let defaultTopics = ["web-notification-message"];

                let optionNotificationLiveStart = localStorage.getItem('optionNotificationLiveStart');
                if (optionNotificationLiveStart !== null && JSON.parse(optionNotificationLiveStart.toLowerCase()) === true) defaultTopics.push("web-notification-live-start");

                let optionNotificationYoutubeVideo = localStorage.getItem('optionNotificationYoutubeVideo');
                if (optionNotificationYoutubeVideo !== null && JSON.parse(optionNotificationYoutubeVideo.toLowerCase()) === true) defaultTopics.push("web-notification-youtube-video");

                let optionNotificationYoutubeVideoVod = localStorage.getItem('optionNotificationYoutubeVideoVod');
                if (optionNotificationYoutubeVideoVod !== null && JSON.parse(optionNotificationYoutubeVideoVod.toLowerCase()) === true) defaultTopics.push("web-notification-youtube-video-vod");

                let optionNotificationYoutubeVideoShorts = localStorage.getItem('optionNotificationYoutubeVideoShorts');
                if (optionNotificationYoutubeVideoShorts !== null && JSON.parse(optionNotificationYoutubeVideoShorts.toLowerCase()) === true) defaultTopics.push("web-notification-youtube-video-shorts");

                let optionNotificationInstagramPhoto = localStorage.getItem('optionNotificationInstagramPhoto');
                if (optionNotificationInstagramPhoto !== null && JSON.parse(optionNotificationInstagramPhoto.toLowerCase()) === true) defaultTopics.push("web-notification-instagram-photo");

                let optionNotificationPlanningTodayCanceled = localStorage.getItem('optionNotificationPlanningTodayCanceled');
                if (optionNotificationPlanningTodayCanceled !== null && JSON.parse(optionNotificationPlanningTodayCanceled.toLowerCase()) === true) defaultTopics.push("web-notification-planning-today-canceled");

                let optionNotificationBeta = localStorage.getItem('optionNotificationBeta');
                if (optionNotificationBeta !== null && JSON.parse(optionNotificationBeta.toLowerCase()) === true) defaultTopics.push("web-notification-beta");

                this.subscribeTokenToTopics(defaultTopics);
            }

        }).catch((error) => {

            this.currentToken = null;

            console.log("Erreur firebase :");
            console.log(error);
        });

        onMessage(messaging, (payload: MessagePayload | any) => {

            if (window.Notification) {

                let notificationOptions: NotificationOptions = {
                    body: payload.data.body,
                    icon: "https://static.locklear.fr/notification/icon-72.png",
                    badge: "https://static.locklear.fr/notification/badge-72.png",
                    lang: "FR",
                    dir: "ltr",
                    data: {}
                };

                if (payload.data.image) notificationOptions.image = payload.data.image;
                if (payload.data.url) notificationOptions.data.url = payload.data.url;

                let notification = new Notification(payload.data.title, notificationOptions);

                notification.onclick = (event) => {

                    event.preventDefault();
                    if (payload.data.url) window.open(payload.data.url, "_blank");
                };
            }
        });
    }

    subscribeTopic(topic) {

        if (this.currentToken === null) return;
        console.log("Inscription aux notifications pour le sujet : " + topic);

        fetch(environment.links.api + "/notification/subscribe/" + this.currentToken + "/" + topic).then(() => {
            console.log("Inscription aux notifications pour le sujet : " + topic + " : Réussi");
        }).catch(() => {

        });
    }

    unsubscribeTopic(topic) {

        if (this.currentToken === null) return;
        console.log("Désinscription des notifications pour le sujet : " + topic);

        fetch(environment.links.api + "/notification/unsubscribe/" + this.currentToken + "/" + topic).then(() => {
            console.log("Désinscription des notifications pour le sujet : " + topic + " : Réussi");
        }).catch(() => {

        });
    }

    subscribeTokenToTopics(topics) {

        if (this.currentToken === null) return;
        console.log("Inscription aux notification pour les sujets : " + JSON.stringify(topics));

        let formData = new FormData();
        formData.append('topics', JSON.stringify(topics));

        fetch(environment.links.api + "/notification/subscribe-multiple/" + this.currentToken, {method: 'post', body: formData}).then(() => {
            console.log("Inscription aux notification pour les sujets : " + JSON.stringify(topics) + " : Réussi");
        }).catch(() => null);
    }
}
