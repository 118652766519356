import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatSidenav} from "@angular/material/sidenav";

import {SocketService} from "./services/socket.service";
import {SidenavLeftService} from "./services/sidenav-left.service";
import {SidenavRightService} from "./services/sidenav-right.service";
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

    @ViewChild('sideNavLeft') public sidenavLeft: MatSidenav;
    @ViewChild('sideNavRight') public sideNavRight: MatSidenav;

    constructor(
        private dialog: MatDialog,
        public socketService: SocketService,
        private sidenavLeftService: SidenavLeftService,
        private sidenavRightService: SidenavRightService
    ) {

    }

    ngAfterViewInit(): void {
        this.sidenavLeftService.setSidenav(this.sidenavLeft);
        this.sidenavRightService.setSidenav(this.sideNavRight);
    }
}
