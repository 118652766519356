import {Component} from '@angular/core';

@Component({
  selector: 'app-tab-soundboard',
  templateUrl: './tab-soundboard.component.html',
  styleUrls: ['./tab-soundboard.component.scss']
})
export class TabSoundboardComponent {

  async openPlaylist(url: string) {
    await window.open(url);
  }
}
